import styles from './css/RecordForm.module.css';
import { useState, useEffect, useMemo, useContext } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useQuery } from 'react-query';
import { getRecords } from '../../../../api/crud';
import getSectionsByRecordType from '../../../../objectManager/lead/layouts';
import DynamicForm from '../../../../components/dynamicForm/DynamicForm';
import formatDateAndTime from '../../../../utils/formatDateAndTime';
import fields from '../../../../objectManager/lead/fields';
import useEffectformValues from '../../../../utils/formValues';
import RelatedList from '../../../../components/relatedList/RelatedList';
import CustomLink from '../../../../components/customLink/CustomLink';
import EmailView from '../../../../components/email/EmailView';
import { Attachments } from '../../../../components/attachments/Attachments';
import Table from '../../../../components/charts/table/Table';
import Field from '../../../../components/field/Field';
import { formatDateTime } from '../../../../utils/dateUtils';
import { Card } from '@mui/material';

const defaultAddress = {
  street: '',
  city: '',
  province: '',
  zip: '',
  country: '',
};

const RecordForm = ({
  isLoading,
  record,
  isEditing,
  onClose,
  fieldsDisabled,
  setFieldsDisabled,
  recordType,
  handleDelete,
}) => {
  const { getUserId } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({});

  // Get the Support Documents for the Lead
  const filter = {
    filterLogic: null,
    filters: [
      {
        field: 'lead',
        operator: 'equals',
        value: record?._id,
      },
    ],
  };

  const { data, isLoading: documentsLoading } = useQuery({
    queryFn: () => getRecords('support-documents', filter),
    queryKey: ['support-documents'],
    enabled: !!record?._id,
  });

  const defaultValues = useEffectformValues(
    isEditing,
    record,
    fields,
    getUserId()
  );

  useEffect(() => {
    setFormValues({
      ...defaultValues,
      recordType: isEditing ? record?.recordType : recordType,
      address: isEditing && record?.address ? record?.address : defaultAddress,
      createdAt: isEditing ? formatDateAndTime(record?.createdAt) : '',
      updatedAt: isEditing ? formatDateAndTime(record?.updatedAt) : '',
    });
  }, [record, isEditing]);

  const caseColumns = useMemo(
    () => [
      {
        accessorFn: (row) => (
          <CustomLink to={`/case/${row._id}`} text={row.subject} />
        ),
        id: 'subject',
        header: 'Subject',
      },
      {
        accessorKey: 'status',
        header: 'Status',
      },
    ],
    []
  );

  const caseFilter = {
    field: 'lead',
    value: record?._id,
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => (
          <CustomLink
            to={`https://www.etenders.gov.za/home/Download/?blobName=${row?.supportDocumentID}${row?.extension}&downloadedFileName=${row?.fileName}`}
            text={row?.fileName}
          />
        ),
        id: 'name',
        header: 'File Name',
      },
      {
        accessorKey: 'updatedBy',
        id: 'updatedBy',
        header: 'Updated By',
      },
      {
        accessorFn: (row) => formatDateTime(row?.dateModified),
        id: 'dateModified',
        header: 'Date Modified',
      },
      {
        accessorFn: (row) => (
          <Field type="checkbox" disabled={true} value={row?.active} />
        ),
        id: 'active',
        header: 'Active',
      },
      {
        accessorFn: (row) => row?.extension?.split('.')[1],
        id: 'extension',
        header: 'Extension',
      },
    ],
    []
  );

  return (
    <div className={styles['lead-record-form']}>
      <div className={isEditing ? 'record-form' : ''}>
        {!isLoading && (
          <DynamicForm
            objectType="lead"
            listViewQueryKey="leads"
            onClose={onClose}
            isEditing={isEditing}
            record={record}
            sections={getSectionsByRecordType(formValues?.recordType)}
            formValues={formValues}
            setFormValues={setFormValues}
            fieldsDisabled={fieldsDisabled}
            setFieldsDisabled={setFieldsDisabled}
            handleDelete={handleDelete}
          />
        )}
        {!isLoading && isEditing && (
          <div className="related-list">
            <RelatedList
              title="Cases"
              filter={caseFilter}
              relatedItem="cases"
              columns={caseColumns}
              isExpandedByDefault={false}
            />
          </div>
        )}
      </div>
      {record?.recordType === 'Email Lead' && record?.emailContent && (
        <>
          <EmailView emailContent={record?.emailContent} />
          <Attachments objectType="lead" relatedRecordId={record?._id} />
        </>
      )}
      <Card sx={{ padding: '10px' }}>
        <Table
          caption="Support Documents"
          isLoading={documentsLoading}
          data={data}
          columns={columns}
        />
      </Card>
    </div>
  );
};

export default RecordForm;
