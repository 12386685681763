import styles from './css/TenderDialog.module.css';
import { useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import Field from '../../components/field/Field';
import Table from '../../components/charts/table/Table';
import CustomLink from '../../components/customLink/CustomLink';
import { formatDateTime } from '../../utils/dateUtils';
import { createRecord, createRecords } from '../../api/crud';
import { toast } from 'react-toastify'; // ToastContainer in PageLayout component

const TenderDialog = ({ open, handleClose, tender }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fields = [
    { label: 'Tender Number', value: tender?.tenderNumber },
    // { label: 'Briefing Date', value: tender?.briefingDate },
    { label: 'Closing Date', value: tender?.closingDate },
    { label: 'Client', value: tender?.client },
    { label: 'Category', value: tender?.category },
    { label: 'Status', value: tender?.status },
    { label: 'Date Published', value: tender?.datePublished },
    { label: 'Briefing Venue', value: tender?.briefingVenue },
    { label: 'Street Name', value: tender?.streetName },
    { label: 'Surburb', value: tender?.surburb },
    { label: 'Town', value: tender?.town },
    { label: 'Code', value: tender?.code },
    { label: 'Conditions', value: tender?.conditions },
    { label: 'Contact Person', value: tender?.contactPerson },
    { label: 'Email', value: tender?.email },
    { label: 'Telephone', value: tender?.telephone },
    { label: 'Fax', value: tender?.fax },
    { label: 'Username', value: tender?.username },
    {
      label: 'Briefing Session',
      value: tender?.briefingSession,
      type: 'checkbox',
    },
    {
      label: 'Briefing Compulsory',
      value: tender?.briefingCompulsory,
      type: 'checkbox',
    },
    { label: 'Validity', value: `${tender?.validity} days` },
    { label: 'Province', value: tender?.province },
    { label: 'Department', value: tender?.department },
    { label: 'eSubmission', value: tender?.eSubmission, type: 'checkbox' },
    {
      label: 'Two EnvelopeSubmission',
      value: tender?.twoEnvelopeSubmission,
      type: 'checkbox',
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => (
          <CustomLink
            to={`https://www.etenders.gov.za/home/Download/?blobName=${row?.supportDocumentID}${row?.extension}&downloadedFileName=${row?.fileName}`}
            text={row?.fileName}
          />
        ),
        id: 'name',
        header: 'File Name',
      },
      {
        accessorKey: 'updatedBy',
        id: 'updatedBy',
        header: 'Updated By',
      },
      {
        accessorFn: (row) => formatDateTime(row?.dateModified),
        id: 'dateModified',
        header: 'Date Modified',
      },
      {
        accessorFn: (row) => (
          <Field type="checkbox" disabled={true} value={row?.active} />
        ),
        id: 'active',
        header: 'Active',
      },
      {
        accessorFn: (row) => row?.extension?.split('.')[1],
        id: 'extension',
        header: 'Extension',
      },
    ],
    []
  );

  const handleCreateLead = async () => {
    setIsSubmitting(true);
    try {
      // Mapping fields from the tender to the Lead
      const leadToCreate = {
        client: tender?.client,
        address: {
          street: tender?.streetName,
          city: tender?.town,
          province: tender?.province,
          zip: tender?.code,
          country: 'South Africa',
        },
        internalComments: tender?.tenderTitle,
        email: tender?.email,
        firstName: tender?.contactPerson,
        industry: tender?.category,
        projectCode: tender?.tenderNumber,
        technicalScope: tender?.tenderTitle,
        phone: tender?.telephone,
      };

      const response = await createRecord('lead', leadToCreate);

      // Get the Lead ID to link to the support documents
      const leadID = response?.record?._id;

      // Get the support documents from the tender
      const tenderSupportDocuments = tender?.documents;

      // Link the support documents from the Tender to the newly created Lead
      const documents = tenderSupportDocuments?.map((document) => ({
        ...document,
        lead: leadID,
      }));

      // Save the Support Documents.
      await createRecords('support-documents', documents);

      setIsSubmitting(false);
      toast.success('Successfully converted to Lead!');
      handleClose(); // close modal
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error converting to Lead:', error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
      <DialogTitle>{tender?.tenderTitle}</DialogTitle>
      <DialogContent>
        <div className={styles['field-container']}>
          {fields.map((field, index) => (
            <Field
              key={index}
              type={field?.type ? field?.type : 'output'}
              disabled={true}
              label={field?.label}
              value={field?.value}
            />
          ))}
        </div>
        <Table
          caption="Support Documents"
          data={tender?.documents}
          columns={columns}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="small"
          style={{ backgroundColor: 'var(--primary-color)' }}
          onClick={handleCreateLead}
          disabled={isSubmitting}
        >
          Save as a Lead
        </Button>
        <Button onClick={handleClose} style={{ color: 'var(--primary-color)' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TenderDialog;
